<template>
  <div>
    <Header :title="'保单详情'" :back="true"/>
    <div v-if="url"  style="width:100%;height:calc(100vh - 3rem);">
        <iframe
        :src="url"
        width="100%"
        scrolling="no"
        height="100%"
        style=""></iframe>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'


// import pdf from 'vue-pdf'
export default {
  components: {
    Header,
  },
  data(){
    return{
      url:'',
      numPages: 0
    }
  },
  created(){
    if(this.$route.query.url){
      this.url = `h5/pdf/web/viewer.html?file=`+encodeURIComponent(this.$route.query.url)
    }
  },
  methods:{
    

  }
}
</script>

<style>

</style>