var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "保单详情", back: true } }),
      _vm.url
        ? _c(
            "div",
            { staticStyle: { width: "100%", height: "calc(100vh - 3rem)" } },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.url,
                  width: "100%",
                  scrolling: "no",
                  height: "100%",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }